import values from 'lodash/values'
import React, { ComponentProps, useState } from 'react'
import GoogleButton from 'react-google-button/es'
import { useApolloClient } from '@apollo/react-hooks'
import { connect } from 'react-redux'

import Box from '@base/Box'
import GENERATE_GOOGLE_LOGIN_URL from '@gql/queries/generateGoogleLoginUrlQuery.api'
import { setCookie, COOKIES } from '@helpers/cookies'
import getLogger from '@src/Logger'
import { AuthKind } from '@store/modules/user/Types'
import { GenerateGoogleLoginUrlQuery, GenerateGoogleLoginUrlQueryVariables } from '@gql/__generated__'
import { useTranslation } from '@src/i18n'
import { snackbarOperations } from '@store/modules/Snackbar'

type Props = ComponentProps<typeof Box> &
  ReduxDispatchProps & {
    kind: AuthKind
    label?: string
    onComplete?: () => void
    coupon?: string
  }

type ReduxDispatchProps = typeof mapDispatchToProps

const GoogleSignIn = ({ kind, label, openSnackbar, onComplete, coupon, ...props }: Props) => {
  const [loading, setLoading] = useState(false)
  const client = useApolloClient()
  const { t } = useTranslation()

  const handleClick = async () => {
    // Sometimes invalid kind is stored in `googleAuthKind` cookie.
    if (!~values(AuthKind).indexOf(kind)) {
      getLogger().error({ obj: { kind } }, 'Invalid Google kind passed before Google sign-in / sign-up  started')
    }

    setLoading(true)

    onComplete && (await onComplete())

    try {
      const { data } = await client.query<GenerateGoogleLoginUrlQuery, GenerateGoogleLoginUrlQueryVariables>({
        query: GENERATE_GOOGLE_LOGIN_URL,
        variables: {
          coupon,
        },
      })
      setCookie(COOKIES.GOOGLE_AUTH, kind)
      setLoading(false)
      window.location.href = data.generateGoogleLoginUrl
    } catch (err) {
      getLogger().error({ err }, 'Failed to generate Google Login URL')
      openSnackbar(t('auth.googleSignIn.error.default'), 'error')
      setLoading(false)
    }
  }

  return (
    <Box {...props}>
      <GoogleButton
        data-testid='googleLoginButton'
        type='light'
        style={{
          width: 'auto',
          paddingRight: 17,
          height: 'auto',
          lineHeight: 1.5,
          minHeight: 50,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '2px solid #2B75F3',
          borderRadius: '4px',
          color: '#2B75F3',
          fontFamily: 'Mirai',
          fontSize: '14px',
          fontWeight: 600,
          boxShadow: 'none',
        }}
        onClick={handleClick}
        disabled={loading}
        {...{ label }}
      />
    </Box>
  )
}

const mapDispatchToProps = {
  openSnackbar: snackbarOperations.open,
}

export default connect(null, mapDispatchToProps)(GoogleSignIn)
