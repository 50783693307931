import { useState, useEffect } from 'react'
import { useTranslation } from '@src/i18n'
import CAN_REDEEM_COUPON from '@gql/queries/canRedeemCouponQuery.api'
import { useApolloClient } from '@apollo/react-hooks'
import Typography from '@base/Typography'
import Box from '@base/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  couponBox: {
    backgroundColor: '#F7F9FB',
    border: '2px solid #ECEDEF',
    borderRadius: '15px',
    display: 'flex',
    width: '100%',
  },
  couponBoxLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '25px',
    width: 'calc(100% - 75px)',
    minHeight: '98px',
  },
  couponBoxRight: {
    borderLeft: '1px dashed #E5E5E5',
    width: '75px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  couponBoxTitle: {
    fontWeight: 600,
  },
  verticalCouponCode: {
    transform: 'matrix(0, 1, -1, 0, 0, 0)',
    color: '#91959E',
    fontFamily: 'Oswald, monospace',
    fontSize: '16px',
    cursor: 'default',
  },
}))

type Props = {
  code: string
  cannotRedeem?: () => void
}

const CouponInfo = ({ code, cannotRedeem }: Props) => {
  const classes = useStyles({})
  const client = useApolloClient()
  const { t } = useTranslation()

  const [packageName, setPackageName] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const canRedeemCoupon = async () => {
      try {
        const {
          data: { canRedeem },
        } = await client.query({ query: CAN_REDEEM_COUPON, variables: { code } })
        setPackageName(canRedeem)
        setLoading(false)
      } catch {
        if (cannotRedeem) cannotRedeem()
      }
    }

    void canRedeemCoupon()
  }, [])

  if (loading) {
    return null
  }

  return (
    <Box className={classes.couponBox}>
      <Box className={classes.couponBoxLeft}>
        <Typography className={classes.couponBoxTitle}>{t(`packages:list.${packageName}.name`)}</Typography>
        {/* TODO: <Typography>expiryDate and seniority</Typography> */}
      </Box>
      <Box className={classes.couponBoxRight}>
        <Typography className={classes.verticalCouponCode}>{code}</Typography>
      </Box>
    </Box>
  )
}

export default CouponInfo
