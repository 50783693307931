import { Field, Form, Formik, FormikActions } from 'formik'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { bindActionCreators } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { useTranslation } from '@src/i18n'
import Alert from '@base/Alert'
import FormikField from '@base/FormikField'
import Box from '@base/Box'
import BaseLink from '@base/Link'
import Typography from '@base/Typography'
import links from '@api/links'
import { AuthError, emailTypoWarning, emailAsciiWarning, createSchema } from '@components/auth/AuthForm'
import GDPRCheck from '@common/GDPRCheck'
import highlight from '@static/icons/underline-highlight.svg'
import lower from '@helpers/lower'
import upper from '@helpers/upper'
import { AuthKind, Role, userOperations, WebLanguage } from '@store/modules/User'
import redirect from '@helpers/redirect'
import { apiErrorToCode } from '@helpers/api'
import FormLayout from '@common/FormLayout'
import ProgressButton from '@base/ProgressButton'
import { snackbarOperations } from '@store/modules/Snackbar'
import { Status, GetStatusQuery } from '@gql/__generated__'
import { SetStatusMutation, SetStatusMutationVariables } from '@gql/__generated__'
import QUERY from '@common/status/Query.api'
import MUTATION from '@common/status/Mutation.api'
import GoogleSignIn from './GoogleSignIn'
import CouponInfo from '@components/coupon/CouponInfo'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CAN_REDEEM_COUPON from '@gql/queries/canRedeemCouponQuery.api'
import { LoginMutationVariables, SignUpMutationVariables, CreatePasswordAndVerifyMutationVariables } from '@gql/__generated__'
import { handleCouponError } from '@components/coupon/utils'
import { SignUpCredentials } from '@components/sign-up/AuthenticationForm'
import { getItem } from '@helpers/localStorage'
import storageKey from '@api/storageKeys'

const useStyles = makeStyles(({ breakpoints, palette }: Theme) => ({
  highlight: {
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '-5%',
      top: '100%',
      width: '110%',
      height: 11,
      background: `url(${highlight}) no-repeat 0% 100% / 100% auto`,
    },
  },
  divider: {
    zIndex: 1,
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: '50%',
      width: '100%',
      height: 1,
      background: palette.divider,
      zIndex: -1,
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '2.5rem',
    [breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  submitButton: {
    marginTop: '16px',
  },

  dividerText: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  couponText: {
    fontWeight: 600,
    color: '#626261',
    fontSize: '1rem',
    textAlign: 'left',
    width: '100%',
    marginTop: '8px',
    marginBottom: '16px',
  },
  checkboxLabel: {
    marginLeft: '-12px',
    textDecoration: 'underline',
    fontWeight: 600,
  },
}))

type OwnProps = {
  kind: AuthKind
  redirectTo?: string
  email?: string
  formSubmitted?: () => void
  onComplete?: () => void
  onGoogleSignIn?: () => void
  coupon?: string
  therapistId?: string
  customLinks?: {
    signup: any
    login: any
  }
  setSignupCredentials?: Dispatch<SetStateAction<SignUpCredentials>>
}

type ReduxDispatchProps = ReturnType<typeof mapDispatchToProps>

type Props = ReduxDispatchProps & OwnProps

type Values = {
  identifier: string
  password?: string
  coupon?: string
  therapistId?: string
}

const initialValues: Values = {
  identifier: '',
  password: '',
  coupon: '',
  therapistId: '',
}

class AuthFormik extends Formik<Values> { }

type LinkProps = {
  type: 'primary' | 'secondary'
  text: string
  linkText: string
  link: any
}

const Link = ({ type, text, linkText, link }: LinkProps) => {
  const PrimaryWrapper = ({ children }: { children: any }) => <Typography variant='body1'>{children}</Typography>

  const SecondaryWrapper = ({ children }: { children: any }) => (
    <Typography variant='body2' color='textSecondary' weight={600}>
      {children}
    </Typography>
  )

  const PrimaryLink = (
    <Typography component='span' weight={700} color='primary'>
      <BaseLink {...link}>{linkText}</BaseLink>
    </Typography>
  )
  const SecondaryLink = (
    <BaseLink {...link} weight={700} underline variant='inherit'>
      {linkText}
    </BaseLink>
  )

  const Wrapper = type === 'primary' ? PrimaryWrapper : SecondaryWrapper

  return (
    <Wrapper>
      {text}
      &nbsp;
      {type === 'primary' ? PrimaryLink : SecondaryLink}
    </Wrapper>
  )
}

const AuthForm = ({
  authorize,
  email,
  kind,
  redirectTo,
  formSubmitted,
  onComplete,
  onGoogleSignIn,
  coupon,
  therapistId,
  customLinks,
  setSignupCredentials,
}: Props) => {
  const classes = useStyles({})
  const { i18n, t } = useTranslation()
  const client = useApolloClient()
  const [changeStatusMutation] = useMutation<SetStatusMutation, SetStatusMutationVariables>(MUTATION)
  const dispatch = useDispatch()
  const [couponInputVisible, setCouponInputVisible] = useState<boolean>(!!initialValues.coupon)

  const signupLink = customLinks?.signup || links.signup
  const loginLink = customLinks?.login || links.login

  const setOnlineStatus = () => {
    const newStatus = Status.Online
    changeStatusMutation({
      variables: {
        status: newStatus,
      },
      update: store => {
        store.writeQuery<GetStatusQuery>({
          query: QUERY,
          data: { getStatus: newStatus },
        })
      },
    }).catch(err =>
      dispatch(
        snackbarOperations.open(
          t([`tutor:dashboard.status.error.${apiErrorToCode(err)}`, 'tutor:dashboard.status.error.default']),
          'error',
        ),
      ),
    )
  }

  const handleSubmit = async (values: Values, actions: FormikActions<Values>): Promise<void> => {
    actions.setSubmitting(true)
    if (kind === AuthKind.Signup) {
      values = {
        identifier: values.identifier,
        coupon: getCoupon(values),
        therapistId: therapistId || getItem(storageKey.THERAPIST_ID),
      }
    }

    try {
      if (getCoupon(values)) {
        await client.query({ query: CAN_REDEEM_COUPON, variables: { code: getCoupon(values) } })
      }

      const response = await authorize(
        kind,
        values as CreatePasswordAndVerifyMutationVariables | LoginMutationVariables | SignUpMutationVariables,
        i18n,
        client,
      )

      if (response?.token?.roles?.includes(Role.Tutor)) {
        setOnlineStatus()
      }
      // Do not set submitting to false here. We continue with redirect
      // eslint-disable-next-line promise/always-return
      onComplete && onComplete()
      if (kind === AuthKind.Signup || (kind === AuthKind.Login && getCoupon(values))) {
        if (formSubmitted) {
          formSubmitted()
        }
        if (setSignupCredentials) {
          setSignupCredentials({ identifier: values.identifier, coupon: values.coupon })
        }
      } else {
        if (redirectTo) {
          redirect(null, redirectTo)
        }
      }
    } catch (error) {
      if (getCoupon(values)) {
        const errorMsg = handleCouponError(coupon, error, t)
        actions.setErrors({ coupon: errorMsg })
      }
      actions.setSubmitting(false)
      actions.setStatus(apiErrorToCode(error))
    }
  }

  const isCouponFromQuery = kind === AuthKind.Signup && coupon
  const isNoCoupon = kind === AuthKind.Signup && !coupon && !couponInputVisible
  const isCouponFromInput = kind === AuthKind.Signup && !coupon && couponInputVisible

  const getCoupon = (values: Values): string | undefined => {
    if (coupon) {
      return coupon
    }
    if (!values.coupon) {
      return undefined
    }
    return values.coupon
  }

  const couponCheckbox = (
    <FormControlLabel
      labelPlacement='start'
      control={
        <Checkbox
          color='primary'
          checked={couponInputVisible}
          inputProps={{}}
          onChange={() => setCouponInputVisible(true)}
        />
      }
      label={<Typography className={classes.checkboxLabel}>{t('coupon.steps.redeemCoupon.purchaseLinkTitle')}</Typography>}
    />
  )

  const couponInput = (
    <Field
      autoComplete='coupon'
      component={FormikField}
      name='coupon'
      normalize={upper}
      label={t('coupon.steps.redeemCoupon.label')}
      placeholder={t('coupon.steps.redeemCoupon.placeholder')}
      type='text'
    />
  )

  const couponInfo = (
    <>
      <Typography component='h1' variant='h6' className={classes.couponText}>
        {t('coupon.steps.couponSignup.afterRegistration')}
      </Typography>
      <CouponInfo code={coupon} />
    </>
  )

  return (
    <AuthFormik
      initialValues={{ ...initialValues, identifier: email || initialValues.identifier }}
      onSubmit={handleSubmit}
      validationSchema={createSchema(kind, i18n.languages[0] as WebLanguage, t)}
      render={({ isSubmitting, status }) => (
        <Form noValidate={true} method='post'>
          <FormLayout>
            <>
              <Box width='100%' maxWidth={360} mx='auto'>
                {status && (
                  <Alert>
                    <AuthError kind={kind} code={status} />
                  </Alert>
                )}
                <Field
                  autoComplete='username'
                  component={FormikField}
                  name='identifier'
                  normalize={lower}
                  label={t(`auth.form.${kind}.fields.identifier`)}
                  type='email'
                  warn={[emailTypoWarning, emailAsciiWarning]}
                />
                {isNoCoupon && couponCheckbox}
                {isCouponFromInput && couponInput}
                {isCouponFromQuery && couponInfo}
                {kind === AuthKind.Login && (
                  <Field
                    autoComplete='current-password'
                    component={FormikField}
                    name='password'
                    label={t('auth.form.login.fields.password')}
                    type='password'
                  />
                )}

                <ProgressButton
                  inProgress={isSubmitting}
                  color='primary'
                  variant='contained'
                  fullWidth
                  className={classes.submitButton}
                >
                  {t(`auth.form.${kind}.button`)}
                </ProgressButton>
              </Box>
              <>
                <Box
                  width='100%'
                  mt={{ xs: 2, sm: 3 }}
                  textAlign='center'
                  position='relative'
                  className={classes.divider}
                >
                  <Box component='span' display='inline-block' bgcolor='#fff' color='#626261' px={2}>
                    <Typography component='span' color='inherit' className={classes.dividerText}>
                      {t('auth.form.or').toLowerCase()}
                    </Typography>
                  </Box>
                </Box>
                <GoogleSignIn
                  width='100%'
                  mt={{ xs: 2, sm: 3 }}
                  label={t(`auth.form.${kind}.google`)}
                  kind={kind}
                  coupon={coupon}
                  onComplete={onGoogleSignIn}
                />
              </>

              {kind === AuthKind.Login && (
                <Box mt={{ xs: 2, sm: 3 }} width='100%' textAlign='left'>
                  <Link
                    type='primary'
                    text={t(`auth.form.${kind}.linkInfo`)}
                    link={signupLink}
                    linkText={t(`auth.form.${kind}.link`)}
                  />
                </Box>
              )}
              {kind === AuthKind.Login && (
                <Box py={2} width='100%' textAlign='left'>
                  <Link
                    type='secondary'
                    text={t('auth.form.login.forgotPasswordLinkInfo')}
                    link={{ href: links.forgottenPassword }}
                    linkText={t('auth.form.login.forgotPasswordLink')}
                  />
                </Box>
              )}
              {kind === AuthKind.Signup && (
                <Box mt={{ xs: 2, sm: 3 }} width='100%' textAlign='left'>
                  <Link
                    type='primary'
                    text={t(`auth.form.${kind}.linkInfo`)}
                    link={loginLink}
                    linkText={t(`auth.form.${kind}.link`)}
                  />
                </Box>
              )}
              {kind === AuthKind.Signup && <GDPRCheck />}
            </>
          </FormLayout>
        </Form>
      )}
    />
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      authorize: userOperations.authorize,
    },
    dispatch,
  )

const ConnectedAuthForm = connect(null, mapDispatchToProps)(AuthForm)

export default ConnectedAuthForm
