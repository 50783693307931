import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  main: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    maxWidth: 350,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 'auto',
    [breakpoints.up('md')]: {
      margin: 0,
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
}))

type Props = {
  children: ReactNode
}

const FormLayout = ({ children }: Props) => {
  const classes = useStyles({})
  return <div className={classes.main}>{children}</div>
}

export default FormLayout
