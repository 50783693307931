import levenshtein from './levenshtein'

/**
 * Domain typos dictionary. Used for cases when Levenshtein distance provides undesired results
 * (e.g. Levenshtein doesn't suggest gmail.com for gmail.cz, because email.cz is closer)
 */
const domainTypos = {
  'gmail.com': ['gmail.cz', 'google.com', 'google.cz', 'gmail.sk', 'gemail.cz', 'gmai.cz', 'gmeil.cz'],
  'seznam.cz': ['emailseznam.cz', 'seynam'],
}

/**
 * List of correct domains. Used for suggestions by Levenshtein distance algorithm
 */
export const correctDomains = [
  'atlas.cz',
  'centrum.cz',
  'centrum.sk',
  'email.cz',
  'gmail.com',
  'hotmail.com',
  'icloud.com',
  'post.cz',
  'seznam.cz',
  'tiscali.cz',
  'volny.cz',
  'yahoo.com',
]

/**
 * Finds email suggestion based on two methods (in given order):
 * - domain typos dictionary
 * - list of correct domain names and allowed ‘Levenshtein distance‘.
 * Doesn't suggest anything when email is correct (returns null).
 */
export const suggestEmail = (email: string, maxDistance = 3): string | null => {
  let suggestedEmail

  suggestedEmail = suggestEmailByDictionary(email)

  if (suggestedEmail) {
    return suggestedEmail
  }

  suggestedEmail = suggestEmailByDistance(email, maxDistance)
  return suggestedEmail
}

const suggestMemory = {}

/**
 * Finds email suggestion based on given domain typos dictionary.
 */
const suggestEmailByDictionary = (email: string): string | null => {
  // Check given email only once
  if (suggestMemory[email] !== undefined) {
    return suggestMemory[email]
  }

  const emailParts = email.split('@')
  const domain = emailParts[1] ? emailParts[1] : null

  // Invalid email
  if (domain === null) {
    return (suggestMemory[email] = null)
  }

  // Domain contain no typo
  if (domainTypos[domain] !== undefined) {
    return (suggestMemory[email] = null)
  }

  const suggestedDomain = Object.keys(domainTypos).find(correctDomain => {
    const typos = domainTypos[correctDomain]
    return !!~typos.indexOf(domain)
  })

  // Typo replacement not found. Email is correct or cannot suggest.
  if (suggestedDomain === undefined) {
    return (suggestMemory[email] = null)
  }

  return (suggestMemory[email] = `${emailParts[0]}@${suggestedDomain}`)
}

/**
 * Finds email suggestion based on list of correct domain names and allowed ‘Levenshtein distance‘.
 */
export const suggestEmailByDistance = (email: string, maxDistance = 3): string | null => {
  const normalizedEmail = email.toLowerCase().trim()
  const atIndex = normalizedEmail.search('@')
  if (atIndex === -1) {
    return null
  }
  const domain = normalizedEmail.substring(atIndex + 1)
  let minDist = -1
  let minDomain = null
  for (let i = 0; i < correctDomains.length; i += 1) {
    const dist = levenshtein(domain, correctDomains[i])
    if (dist === 0) {
      return null // email already uses correct domain
    }
    if (dist <= maxDistance && (minDist === -1 || minDist > dist)) {
      minDist = dist
      minDomain = correctDomains[i]
    }
  }
  if (minDomain == null) {
    return null
  }

  return email.substring(0, atIndex + 1) + minDomain
}
