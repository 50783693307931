import { Trans } from '@src/i18n'
import { AuthKind } from '@store/modules/user/Types'

type AuthErrorProps = {
  kind: AuthKind
  code: string
}

const AuthError = ({ code, kind }: AuthErrorProps) => (
  <Trans
    i18nKey={[`auth.apiErrors.${code}`, kind === AuthKind.Login ? 'auth.form.errorInLogin' : 'auth.form.errorInSignup']}
  />
)

export default AuthError
