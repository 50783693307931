import React from 'react'

import { useTranslation } from '@src/i18n'

export const emailAsciiWarning = (value: string) => {
  // email is in token which is send in HTTP header and that needs to be ASCII
  const isAscii = !!value?.match(/^[\x20-\x7F]+$/) // https://en.wikipedia.org/wiki/ASCII

  if (isAscii) return null

  return <EmailAscii />
}

const EmailAscii = () => {
  const { t } = useTranslation()

  return <>{t('auth.warnings.email.forbidden')}</>
}

export default EmailAscii
