import { makeStyles, Theme } from '@material-ui/core'
import React from 'react'

import { suggestEmail } from '@helpers/email'
import { Trans } from '@src/i18n'

type EmailWarningProps = {
  suggestion: string
  onSuggest: (value: string) => void
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  suggestion: {
    color: palette.primary.main,
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
}))

export const emailTypoWarning = (value, { field: { name }, form: { setFieldValue } }) => {
  const suggestion = suggestEmail(value)

  if (!suggestion) {
    return null
  }

  return <EmailTypo suggestion={suggestion} onSuggest={() => setFieldValue(name, suggestion)} />
}

const EmailTypo = ({ onSuggest, suggestion }: EmailWarningProps) => {
  const classes = useStyles({})

  return (
    <Trans
      i18nKey='auth.warnings.email.domainTypo'
      components={[
        <b key='suggestion' className={classes.suggestion} onClick={() => onSuggest(suggestion)} role='button' />,
      ]}
      values={{ suggestedEmail: suggestion }}
    />
  )
}

export default EmailTypo
