import getLogger from '@src/Logger'

const logger = getLogger()

export const handleCouponError = (coupon, error, t) => {
  const match = /COUPON_NOT_FOUND|COUPON_CANCELED|COUPON_EXPIRED|COUPON_ALL_REDEMPTIONS_USED|COUPON_USED_BY_OTHER_USER|COUPON_USED_BY_YOU/
  if (error.message.match(match)) {
    const code = error.message.match(match)[0]
    const message = t(`coupon.error.${code}`)
    logger.info(`(code: ${coupon}) ${code}`)
    return message
  }
  logger.error(error)
  return t('coupon.error.generic')
}
