import memoize from 'lodash/memoize'
import * as Yup from 'yup'

import { AuthKind } from '@store/modules/user/Types'
import { WebLanguage } from '@gql/__generated__'

export { default as AuthError } from './authForm/AuthError'
export { emailTypoWarning } from './authForm/EmailTypo'
export { emailAsciiWarning } from './authForm/EmailAscii'

const createLoginSchema = memoize(
  (webLanguage: WebLanguage, t) =>
    Yup.object().shape({
      identifier: Yup.string().required(t('auth.validation.email.required')).email(t('auth.validation.email.invalid')),
      password: Yup.string().required(t('auth.validation.password.required')),
    }),
  // Memoize using webLanguage as cache key
  (webLanguage: WebLanguage) => webLanguage,
)

const createSignupSchema = memoize(
  (webLanguage: WebLanguage, t) =>
    Yup.object().shape({
      identifier: Yup.string().required(t('auth.validation.email.required')).email(t('auth.validation.email.invalid')),
      coupon: Yup.string().default(undefined),
    }),
  // Memoize using webLanguage as cache key
  (webLanguage: WebLanguage) => webLanguage,
)

/**
 * Returns schema for given auth form (login / signup form)
 */
export const createSchema = (kind: AuthKind, webLanguage: WebLanguage, t) =>
  kind === AuthKind.Signup ? createSignupSchema(webLanguage, t) : createLoginSchema(webLanguage, t)
