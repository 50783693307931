/**
 * Compute ‘Levenshtein distance‘.
 * Informally, the Levenshtein distance between two words is equal to the number of single-character edits
 * required to change one word into the other.
 */
const levenshtein = (a: string, b: string): number => {
  if (!a || !b) return (a || b).length
  const m = []
  for (let i = 0; i <= b.length; i += 1) {
    m[i] = [i]
    if (i !== 0) {
      for (let j = 0; j <= a.length; j += 1) {
        m[0][j] = j
        if (j !== 0) {
          m[i][j] =
            b.charAt(i - 1) === a.charAt(j - 1)
              ? m[i - 1][j - 1]
              : Math.min(m[i - 1][j - 1] + 1, m[i][j - 1] + 1, m[i - 1][j] + 1)
        }
      }
    }
  }
  return m[b.length][a.length]
}

export default levenshtein
